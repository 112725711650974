.BMXTabsSelect {
    position: relative;
    .Small{
        height: max-content;
        div {
            min-height: auto !important;
            height: 1.2rem;
            .MuiTabs-flexContainer,
            .MuiButtonBase-root {
                min-height: auto !important;
                height: auto;
                padding-top:0;
                padding-bottom:0;
                min-width: .5rem !important;
                color: var(--BMX-secondary) !important;
                padding: 0 8px !important;
            }
        }
    }

    div {
        min-height: auto !important;
        height: 2.65rem;
        .MuiTabs-flexContainer,
        .MuiButtonBase-root {
            min-height: auto !important;
            height: 2.3rem;
            min-width: 1rem !important;
            color: var(--BMX-secondary) !important;

        }
    }

    .Separator {   
        position: relative;
        margin-top: 0;
        margin-bottom: 5px !important;
        &::before{
            top:auto !important;
            bottom:0 !important;
        }
        p { 
            font-size: 0.8rem !important;
            bottom: -15px !important;
            font-weight: 400 !important;   
            top: auto !important;
            transform: none !important;     
            margin-top: 0 !important;
            margin-bottom: 1rem !important;
        }
    }
    button {
        font-size: 0.7em !important;
        span{
            margin-bottom: 0 !important;
        }
    }
    span,
    .Mui-selected {
        color: var(--BMX-secondary) !important;
    }
    .MuiTabs-indicator {
        background-color: var(--BMX-warning);
    }
}
