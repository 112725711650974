.RolesItem{
    display: flex;
    align-items: center;
    .material-icons {
        color: var(--BMX-grey) !important;
    }
    .Buttons{
        justify-content: center;
        margin: 0 .4rem 0 0;
        color: var(--BMX-white) !important;
        font-weight: 100;
        font-size: .6rem;
        background-color: var(--BMX-grey) !important;

        &.selected{
            background-color: var(--BMX-secondary) !important;
        }
    }
}