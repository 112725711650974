.Popover-container {
  width: 15rem;
  height: 18rem;

  .content {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: var(--BMX-dark-grey);
    border-bottom: 1px solid var(--BMX-menu-grey);
    margin-bottom: 15px;
    padding: 0.3rem;
    margin: 0.5rem;
  }
  .content:first-child {
    margin-top: 2rem;
  }
}
