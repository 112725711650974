.AddAttachmentsFormStepOne {
  display: flex;
  justify-content: space-between;

  #fileList {
    display: flex;
    flex-wrap: wrap;
    max-height: 200px;
    overflow: auto;
  }

  .uploadProgress {
    font-size: 0.8rem;
    font-weight: bold;
  }

  .IconArea {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    padding-bottom: 2.5rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    h4 {
      color: var(--BMX-secondary);
      font-weight: 700;
      font-size: 1.5rem !important;
    }
    span:nth-of-type(1) {
      transform: scale(1.2);
      margin-bottom: 0.5rem;
    }
    span {
      color: var(--BMX-details--header-font-grey);
    }
  }
  .summaryAttachments {
    margin-bottom: 0;
    font-size: 12px;
    color: #ababab;
  }
  .attachmentsLine {
    position: relative;
    background: #fff;
    width: 100%;
    height: 5px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .FileArea {
    min-width: 100%;
    min-height: 70%;
    background: var(--BMX-details-grey);
  }
}
