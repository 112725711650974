.BMXSecretaryModal {
    top: 50% !important;
    left: 50% !important;
    background-color: var(--BMX-white);
    transform: translate(-50%, -50%) !important;
    position: fixed;
    min-width: 600px;
    max-width: max-content;
    display: grid;
    grid-template-rows: 4.625rem 1fr 4.625rem;
    --border: 4px;
    border-radius: var(--border) !important;
    animation: hideModal 0.25s linear;

    &.fadeIn {
        animation: showModal 0.3s both;
    }

    &.fadeOut {
        animation: hideModal 0.3s both;
    }

    .Header {
        position: relative;
        background-size: cover;
        text-align: center;
        color: var(--BMX-dark-grey);
        align-items: center;
        font-weight: 350;
        display: flex;
        justify-content: space-between;
        font-weight: 400;
        font-size: 0.875rem;

        &-text {
            margin-top: 0.5rem;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: 35px;
            cursor: pointer;
        }

        > .CloseIcon {
            margin-top: 0.5rem;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 35px;
            cursor: pointer;
            color: var(--BMX-grey);
        }

        border-top-left-radius: var(--border);
        border-top-right-radius: var(--border);
    }

    .Body {
        background: var(--BMX-white) !important;
        min-height: 600px;
        border-bottom: 1px solid var(--BMX-primary);
        border-top: 1px solid var(--BMX-primary);
        margin: 0 1.75rem;
        form {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: .3rem;  
            height: 100%;       
            
        }
    }

    .Buttons {
        border-bottom-left-radius: var(--border);
        border-bottom-right-radius: var(--border);
        background: var(--BMX-white) !important;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 1.6rem;
        > div {
            button {
                margin: 0 5px;
            }
        }
    }
}

@keyframes showModal {
    0% {
        transform: translate(-50%, -50%) scale(0.1);
        opacity: 0;
    }

    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}

@keyframes hideModal {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }

    100% {
        transform: translate(-50%, -50%) scale(0.1);
        opacity: 0;
    }
}

.MuiButton-root {
    color: grey !important;
}

// .AddAttachmentsForm>div>div {
//         display: block !important;
// }
