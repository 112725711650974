.BMXSelectField {
  display: flex;
  align-items: flex-end;
  margin: 0 0 19px 0;


  .MuiInput-root {
    overflow-x: hidden !important;
  }

  .MuiFormControl-root {
    padding: 0 !important;
    margin: 0 !important;
  }

  .MuiSelect-select {
    overflow: hidden !important;
  }

  .MuiFormControlLabel-root,
  .MuiInputLabel-root {
    color: var(--BMX-grey);
    font-size: 0.9rem;
  }

  .MuiInput-root {
    font-size: 0.9rem;
    margin-top: 13px;
    overflow-x: hidden !important;

    &::after {
      border-bottom: solid 2px var(--BMX-secondary);
    }
  }

  .Mui-focused {
    color: var(--BMX-secondary) !important;
  }

  .MuiFormControl-root,
  .MuiTextField-root {
    width: 100%;
    height: 100%;
  }

  .MuiFormHelperText-root {
    position: absolute;
    bottom: -1.4rem;
    left: 0;
  }

  @keyframes fromMiddle {
    0% {
      width: 0%;
    }

    100% {
      width: 100%;
    }
  }
}

.itemsList {
  padding: 12px 16px !important;
}