.files {
  margin-top: 10px;
  max-height: 120px;
  min-height: 120px;
  display: flex;

  .item-container {
    position: relative;
  }

  .item {
    height: 100px;
    overflow: hidden;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    span {
      font-size: 2.1rem;
    }
    .name {
      text-align: center;
    }
    p {
      margin: 0;
      font-size: 0.8rem;
      max-width: 100%;
      color: var(--BMX-grey);
    }
  }
  .remove {
    position: absolute;
    top: -0.8rem;
    right: -0.8rem;
    width: 30px;
    height: 30px;
    min-width: auto;
    padding: 0 !important;
    border-radius: 25px;
    z-index: 2;
  }
}
