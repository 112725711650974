.AlertFormStepOne {
    .clearSelect{
        button {
            margin-top: 1rem;
            span {
                font-size: 1rem !important;
            }
        }
    }
}
