.BMXTimePicker  {

    .MuiInput-root{
        margin-top: 11px !important;
        font-size: 0.9em;
    }
    label {
        font-size: 0.9em !important;
    }
    .MuiInputBase-root {
        input {
            padding-bottom: 7px;
        }
    }
}
