.MuiCard-root {
  width: 300px;
  height: 200px;
  display: grid;
  grid-template-rows: max-content 1fr;
  animation: open 0.2s;
  padding: 16px;

  >div {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 10px 0;

    >h4 {
      color: var(--BMX-secondary) !important;
      caret-color: var(--BMX-secondary) !important;
      font-size: 1.3rem;
      margin: 0 !important;
    }

    .CloseIcon {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 0;
      cursor: pointer;
      color: var(--BMX-grey);
    }
  }
}

@keyframes open {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}