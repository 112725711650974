.SecretariatCorespondenceForm {
  position: relative;
  height: 100%;

  > div {
    position: relative;
    height: 100%;

    > div {
      position: relative;
      height: 100%;
    }
  }

  [data-swipeable] {
    overflow: hidden;
  }

  .react-swipeable-view-container {
    will-change: auto !important;
  }

  .SecretariatCorespondenceStepOne {
    position: relative;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .row {
      margin: 0.2rem 0;
    }
  }

  .Add_To_Table {
    position: absolute;
    bottom: -35px !important;
    right: -15px !important;
  }

  .MuiInputLabel-root,
  .MuiInput-root {
    font-size: 0.8rem;
  }

  .MuiAutocomplete-root {
    .MuiInputLabel-root {
      top: -12px;
    }
    .MuiInputLabel-shrink {
      margin-top: 18px;   
    }
  }

  @media (width >= 1700) {
    .extraLarge {
      flex: 0 0 auto !important;
      width: 75% !important;
    }
  }
}
