.ButtonAreaResetPassword{
    position: absolute !important;
    bottom: 10px;
    right: 10px;
    > button {
        margin: 0 0 0 10px;
    }

    .ResetPasswordLoading{
        width: 5rem;
    }
}

.ResetPasswordSwipe{
    .react-swipeable-view-container{
        width: 100%;
        height: 100%;

        > div{
        overflow: hidden !important;
        }
    }
}