.BMXTextField {
  --borderColor: var(--BMX-warning);
  margin: 0px 0 2rem 0;
  .MuiFormControlLabel-root,
  .MuiInputLabel-root {
    color: var(--BMX-grey);
    font-size: 0.9rem;
    .MuiCheckbox-colorPrimary{
      color: var(--BMX-secondary);      
    }
  }
  .MuiInput-root {
    font-size: 0.9rem;
    margin-top: 13px;
    &::after{
      border-bottom: solid 2px var(--BMX-secondary);
    }
  }
  
  .Mui-focused{
    color: var(--BMX-secondary) !important;
  }

  .MuiFormControl-root,
  .MuiTextField-root {
    width: 100%;
    height: 100%;
  }

  &.BottomLine{
    .MuiInput-root {
      font-size: 0.9rem;
      margin-top: 13px;
      &::after{
        border-bottom: none !important;
      }
    }
    .Mui-focused{
      color: var(--BMX-grey) !important;
    }
  }
  .MuiFormHelperText-root{
    position: absolute;
    bottom:-1.4rem;
    left: 0;
  }

  &.BottomLine:focus-within {
    position: relative;
    .BottomBar {
      position: absolute;
      background-color: var(--borderColor);
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      animation: fromMiddle 0.5s both;
    }
  }
  &.BottomLine {
    position: relative;
    .active ~ .BottomBar {
      position: absolute;
      background-color: var(--borderColor);
      width: 100%;
      height: 1px;
      bottom: 0px;
      left: 0;
      animation: fromMiddle 0.5s both;
    }
  }
}

@keyframes fromMiddle {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.BMXCheckbox {
  padding:0 0 0 12px;
  span {
    font-size: 0.9rem !important;
  }
}