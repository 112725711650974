.BMXSearchField {
    width: max-content !important;
    display: flex;
    align-items: flex-end;
    position: relative;
    max-width: 18rem;
    min-width: 0 !important;
    input{
        width: 18rem;
        font-size: .9rem;
    }
    .SearchIcon {
        color: var(--BMX-primary);
    }
    .LoopIcon,
    .SettingsIcon {
        font-size: 16px !important;
        color: #D3CFCF;
    }
    
    .MuiInput-root {
        &::after {
            border-bottom: 2px var(--BMX-secondary) solid;
        }
        &:hover:not(.Mui-disabled):before {           
            border-bottom: 1px var(--BMX-black) solid  !important;
        }
        
    }
    .SearchFieldsArea {
        position: absolute;
        bottom: 20px;
        right: 0;
        transform: translate(0%, 100%);
        z-index: 99;
        padding: 1rem 2rem 1rem 1rem;
        p {
            font-size: 1rem;
            &::selection {
                background: transparent;
            }
        }
        > div {
            display: flex;
            align-items: center;
            > span{
                color: var(--BMX-primary);
            }
            > label {
                color: var(--BMX-dark-grey);
                cursor: pointer;
                &::selection {
                    background: transparent;
                }
            }
        }
    }
}
