// === SEPARATOR COMPONENT === //
.Separator // === WRAPPER CLASS
{
  color: var(--BMX-primary);
  position: relative;
  text-align: center;
  display: flex;
  width: 100%;
  font-size: 15px;
  font-weight: 400 !important;
  text-transform: uppercase;
  height: 23px;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  &::before // === LINES
  {
    content: '';
    position: absolute;
    top: 25px;
    right: 0;
    height: 2px;
    bottom: 0;
    width: 100%;
    background-color: var(--BMX-primary);
  }
  p // === TEXT IN SEPARATOR
  {
    font-weight: 400 !important;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0%, -50%);
    z-index: 2;
    color: var(--BMX-secondary);
    padding-left: 25px;
    padding-right: 70px;
    font-size: 17px;
  }
  span {
    font-size: 14px;
    color: grey;
    margin-left: 10px;
    text-transform: none;
  }
}
.mini {
  text-transform: none !important;
  &::before {
    height: 1px !important;
  }
}