.SecretariatCorespondenceModal {
  .MuiPaper-root {
    position: absolute;
    bottom: auto;
    right: auto;
    top: 50%;
    left: 50%;
    translate: -50% -50% !important;
    width: 80vw;
    height: auto;
  }

  .header-text {
    cursor: pointer;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 90%;
    height: 100%;
    cursor: pointer;
  }

  .line-after {
    position: relative;
    display: block;
  }
  .line-after span {
    display: block;
    position: relative;
  }
  .line-after span:after {
    content: "";
    position: absolute;
    width: 30rem;
    top: 70%;
    margin-left: 10px;
    border-top: 1px solid #ccc;
  }

  .SecretariatCorespondenceModalHeader {
    color: var(--BMX-dark-grey);
    margin-left: 15rem;
    font-size: 0.8rem !important;
    display: flex;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    .editButton {
      position: absolute;
      margin: auto 0;
      top: 1rem;
      left: 12rem;
    }

    p {
      margin: 0 !important;
    }

    .Header,
    .Data {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .Header {
      align-items: flex-end;
    }

    .Data {
      margin-left: 0.3rem;
      align-items: flex-start;

      p {
        font-weight: 300;
      }
    }
  }

  .SecretariatCorespondenceModalBody {
    min-height: 76vh;
    border-top: thin solid var(--BMX-primary);
    border-bottom: thin solid var(--BMX-primary);
    display: grid !important;
    grid-template-columns: 0.5fr 0.5fr !important;
    grid-template-rows: 0.86fr 0.14fr;
    position: relative;

    > div {
      position: relative;
      height: 100%;
      width: 100%;
      padding: 0 0.2rem;
    }

    .FormArea {
      position: absolute;
      grid-column: 1/2;
      grid-row: 1/2 span;

      .TabsArea {
        margin-top: 0.1rem;
        padding: 0 0.4rem 0 0.4rem;
        background: var(--BMX-details-grey);
        min-height: auto !important;
        height: 2.375rem;

        .MuiTabs-flexContainer,
        .MuiButtonBase-root {
          min-height: auto !important;
          height: 2.375rem;
        }

        .MuiTab-textColorPrimary {
          text-transform: none;
          font-weight: 400;
          font-size: 0.875rem;
          color: var(--BMX-dark-grey);
        }

        .Mui-selected {
          color: var(--BMX-dark-grey);
        }

        .MuiTabs-indicator {
          background-color: var(--BMX-dark-grey) !important;
        }
      }

      > .FormBodyArea {
        background: var(--BMX-white);
        height: calc(100% - 3.5rem);
        padding: 0.4rem;
      }

      &::before {
        content: "";
        position: absolute;
        right: 0;
        top: 2%;
        height: 96%;
        width: 1px;
        border-right: thin solid var(--BMX-details-grey);
      }
    }

    .EmailArea {
      height: 100%;
      width: 100%;
      position: absolute;
      overflow-y: auto;
      overflow-x: hidden;
      grid-column: 2/3;
      grid-row: 1/2;
      max-height: 56vh;
      padding: 1rem;
      isolation: isolate;
    }

    .AttachmentsArea {
      position: absolute;
      color: var(--BMX-dark-grey);
      font-size: 0.9rem !important;

      .AttachmentItem {
        font-size: inherit;
        cursor: pointer;
        margin: 0.2rem 0;
        display: flex;
        align-items: center;
        width: max-content;
        max-width: 60%;

        .material-icons {
          font-size: 1.1rem;
        }
        .text {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .AttachmentCounter {
        position: fixed;
        bottom: 5rem;
        right: 1.6rem;
        font-style: normal;
        font-weight: 400;
        font-size: 0.8rem;
      }

      .AttachmentButtons {
        position: fixed;
        bottom: 8.8rem;
        right: 1.6rem;
      }

      &::before {
        content: "";
        position: sticky;
        display: block;
        left: 1%;
        top: 0;
        height: 1px;
        width: 97%;
        border-top: thin solid var(--BMX-details-grey);
      }

      overflow: auto;
      grid-column: 2/3;
      grid-row: 2/3;
    }
  }

  .ModalButton {
    margin: 10px 0 10px auto !important;
    right: 0;
  }
}
