.ChangePasswordField {
  padding-right: 5px;
  position: relative;
  &::before {
    position: absolute;
    content: attr(count);
    bottom: -1.5rem;
    right: 0;
  }
}
