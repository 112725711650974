.BMXInputFile {
  --borderColor: var(--BMX-warning);
  width: 100%;
  margin: 0px 0 19px 0;
  .InputFileMessage{
    display: flex;
    span {      
      color: var(--BMX-grey);
      font-size: 0.9rem;
      margin-left: auto;
      margin-right: 30px;
    }
  }
  .IconArea {
    display: flex;
    align-items: center;
    .MuiInputBase-root {      
        .inputValue{
          display: flex;
          width: 100%;
          div {
            display: flex;
            width: 100%;
            div {
              margin-left: 5px
            }
          }
        }
        
      
    }
    .MuiIconButton-root  {
      width: 30px;
      height: 30px;
      margin-top: 10px;
      .material-icons {
        font-size: 1rem;
        margin-top: 0px;
        margin-right: 0px
      }
    }
  }
  .material-icons {
    margin-top: 15px;
    margin-right: 5px
  }
  .MuiFormControlLabel-root,
  .MuiInputLabel-root {
    color: var(--BMX-grey);
    font-size: 0.9rem;
    
  }
  .MuiInput-root {
    font-size: 0.9rem;
    margin-top: 13px;
    &::after{
      border-bottom: solid 2px var(--BMX-secondary);
    }
  }
  
  .Mui-focused{
    color: var(--BMX-secondary) !important;
  }

  .MuiFormControl-root,
  .MuiTextField-root {
    width: 100%;
    height: 100%;
  }

  .MuiFormHelperText-root{
    position: absolute;
    bottom:-1.4rem;
    left: 0;
  }

}
