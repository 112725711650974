.QuilArea {
    display: flex;
    flex-direction: column;
    height: 100%;
    // .ql-toolbar {
    //     max-height: 25%;
    // }
    // .ql-container {
    //     height: 100%;
    // }
}
