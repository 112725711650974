.doubleLine {
  text-overflow: ellipsis !important;
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  span:nth-of-type(1) {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 !important;
  }

  span:nth-of-type(2) {
    font-size: 12px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    position: sticky;
    padding: 0 !important;
  }
}