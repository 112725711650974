.StorageToUserStepOne {
    display: flex;
    justify-content: flex-start !important;
    .summary {
      font-size: 0.8rem;
      color: var(--BMX-grey);
      .area {
        overflow: auto;
        max-height: 200px;
      }
    }
    .MuiInput-root::before {
      bottom: 3px !important;
    }
    .MuiInput-root::after {
      bottom: 3px !important;
    }
    .MuiAutocomplete-tag {
      font-size: 0.9rem !important;
    }
    .MuiAutocomplete-root {
      .MuiInput-root {
        min-height: 44px !important;
      }
    }
  }