@import 'react-image-crop/src/ReactCrop.scss';

.ChangeImageFormStepOne {
  .IconArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    span {
      transform: scale(1.5);
      margin-bottom: 1rem;
      color: var(--BMX-secondary);
    }
  }
  .PictureArea {
    min-width: 40%;
    min-height: 70%;
    border: 0.25rem dashed var(--BMX-secondary);
    margin: 2rem 1rem 1.5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    > div {
      width: 100%;
      height: 100%;
      > div {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
     
    }
  }
}
