.userView {
    cursor: pointer;
    .MuiAvatar-root {
        font-size: .9rem;
    }
    margin-right: 5px;
    display: flex;
    align-items: center;
    
    padding: 4px 0 4px 0;
    font-size: .9rem;
    color: var(--BMX-black);      
    &.line {
        border-bottom: 1px solid var(--BMX-secondary);
    }
}