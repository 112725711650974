.BMXConfirmModal {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important; 
  position: fixed;
  background: white;
  min-width: 700px;
  max-width: max-content;
  display: flex;
  flex-direction: column;
  grid-template-rows: 3.5rem 1fr 3.5rem;
  --border: 4px;
  border-radius: var(--border) !important;
  animation: hideConfirmModal 0.25s linear;
  &.fadeIn {
    animation: showConfirmModal 0.3s both;
  }
  &.fadeOut {
    animation: hideConfirmModal 0.3s both;
  }
 
  .Body {
    background: var(--BMX-white) !important;    
    border-radius: var(--border) !important;
    min-height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;  
    .BodyContent {
      height: 100%;
      width: 100%;
      form{
        height: 100%;
        > div{
          height: 100%;
          position: relative;
          > div{
            height: 100%;
            width: 100%;
            position: absolute;
            > div{
              height: 100%;
              > div{
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;                
              }
            }
          }
        }   
      }
      height: calc(100% - 3.1rem);
      [aria-hidden] {
        overflow: hidden !important;
      }
      margin: 1rem ;
      
    }
  }
  .Buttons {  
    display: flex;
    justify-content: center;
    padding: 0 5px;
    align-items: center;
    margin-bottom: 2rem;
    > div {
      button {
        padding: 8px 20px;
        min-width: 6rem;
        color: White !important;
        margin: 0 5px;
      }
    }
  }
}

@keyframes showConfirmModal {
  0% {
    transform: translate(-50%, -50%) scale(0.1);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}
@keyframes hideConfirmModal {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(0.1);
    opacity: 0;
  }
}

.MuiButton-root {
  color: grey !important
}