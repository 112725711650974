.BMXModal {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important; 
  position: fixed;
  background: rgba(0, 17, 39, 0.75) !important;
  min-width: 600px;
  max-width: max-content;
  display: grid;
  grid-template-rows: 3.5rem 1fr 3.5rem;
  --border: 4px;
  border-radius: var(--border) !important;
  animation: hideModal 0.25s linear;
  &.fadeIn {
    animation: showModal 0.3s both;
  }
  &.fadeOut {
    animation: hideModal 0.3s both;
  }
  .Header {
    position: relative;
    background-color: rgba(0, 17, 39, 0.75);
    background-image: url("../../../assets/img/global/modals/modal_background.jpg");
    background-blend-mode: darken;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    color: var(--BMX-white);
    font-size: 1.5rem;
    font-weight: 350;
    display: flex;
    justify-content: center;
    align-items: center;
    > .CloseIcon {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 15px;
      cursor: pointer;
      color: var(--BMX-grey);
    }
    border-top-left-radius: var(--border);
    border-top-right-radius: var(--border);
  }
  .Body {
    background: var(--BMX-white) !important;
    min-height: 600px;
    .BodyContent {
      form{
        height: 100%;
        > div{
          height: 100%;
          position: relative;
          > div{
            height: 100%;
            width: 100%;
            position: absolute;
            > div{
              height: 100%;
              > div{
                height: 100%;
                display: flex;
                flex-direction: column;
                // justify-content: space-between;                
              }
            }
          }
        }   
      }
      height: calc(100% - 3.1rem);
      [aria-hidden] {
        overflow: hidden !important;
      }
      margin: 2rem ;
      padding: 0rem 0 3rem 0
    }
    .Step {
      
      background: var(--BMX-modal-grey);
      display: flex;

      

      .ModalSeparator {
        flex: 1;
        height: 1px;
        background: #bdbdbd;
        margin: auto 0;
      }
      .MuiStepper-root {
        flex: 1 0 auto;
        height: 50px !important;
        justify-content: space-around;
        .MuiStep-root {
          .MuiStepLabel-root {
            .MuiStepLabel-iconContainer {
              svg {
                font-size: 1.2rem;
              }
            }
          }
        }
        .MuiStepLabel-root {
          cursor: pointer;
          span {
            svg {
              &.Mui-active {
                color: var(--BMX-secondary) !important;
                &.Mui-error{
                  color: #d32f2f !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .Buttons {
    border-bottom-left-radius: var(--border);
    border-bottom-right-radius: var(--border);
    background: var(--BMX-modal-grey);
    display: flex;
    justify-content: flex-end;
    padding: 0 5px;
    align-items: center;
    > div {
      button {
        margin: 0 5px;
      }
    }
  }
}

@keyframes showModal {
  0% {
    transform: translate(-50%, -50%) scale(0.1);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}
@keyframes hideModal {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(0.1);
    opacity: 0;
  }
}

.MuiButton-root {
  color: grey !important
}
// .AddAttachmentsForm>div>div {
//         display: block !important;   
// }